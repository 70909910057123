html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  width: 100%; /* Take full width */
  height: 100%; /* Take full height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%; /* Make root div fill the full width */
  height: 100%; /* Make root div fill the full height */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
}

/* Theres another .container in public/bootstrap.css that i copied from but this one ensures content doesnt get cut on the right on mobile devices small */
.container { 
  padding-right: 57px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.header-container { 
  margin-right: auto;
  margin-left: auto;
}

.intro-text h1 {
  font-size: 66px; /* Default larger size for all screens */
}

@media (max-width: 768px) {
  .intro-text h1 {
    font-size: 50px; /* Adjust font size for smaller screens */
  }

  .intro-text p {
    font-size: 16px; /* Adjust paragraph font size */
  }
}
